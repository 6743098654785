<script>
import { mapGetters } from 'vuex';

export default {
  props: {
    hideOptions: {
      type: Array,
      default: () => {
        return [];
      },
    },
    buttonOption: {
      type: String,
      default: () => {
        return 'boost';
      }
    },
    model: {
      type: Object,
      default: () => {
        return null;
      }
    }
  },
  data(){
    return {
      options: [
        {
          value: 'boost',
          icon: 'fa fa-rocket',
          title: this.getTrans('messages.boost'),
          click: () => {
            this.boostModelProfiles(this.model)
          },
          hide: () => {
            return !this.userCan('orders-create');
          }
        },
        {
          value: 'edit',
          title: this.getTrans('messages.edit'),
          click: () => {
            this.editModel(this.model);
          },
          hide: () => {
            return !this.userCan('models-update');
          }
        },
        {
          value: 'stats',
          title:this.getTrans('messages.stats'),
          click: () => {
            this.goToModelStats(this.model);
          },
          hide: () => {
            return !this.userCan('statistic');
          }
        },
        {
          value: 'delete',
          title: this.getTrans('messages.delete'),
          click: () => {
            this.deleteModel(this.model);
          },
          hide: () => {
            return !this.userCan('models-delete');
          }
        }
      ]
    }
  },
  computed: {
    ...mapGetters('User', {userCan: 'can'}),
    ...mapGetters('Profile', ['getCamsiteProfilesFromList']),
    dropdownOptionObjects() {
      return this.options.filter(o => o.value !== this.buttonOption && this.hideOptions.indexOf(o.value) < 0 && !o.hide())
    },
    buttonOptionObject() {
      return this.options.find(o => o.value === this.buttonOption);
    },
    profiles() {
      return this.model?.profiles?.data?.length ? this.model.profiles.data : [];
    }
  },
  methods: {
    boostModelProfiles(model) {
      this.$router.push({name: 'boost', params: {profiles: JSON.stringify(this.getCamsiteProfilesFromList(model.profiles.data))}});
    },
    editModel(model) {
      this.$router.push({name:'model_settings', params: {modelId: model.id}});
    },
    goToModelStats(model) {
      this.$router.push({
        name: "ProfileStats",
        params: { profileIds: model.profiles.data.map(p=>p.id) }
      });
    },
    deleteModel(model) {
      this.$swal.fire({
        showCancelButton: true,
        title: this.getTransChoise('messages.delete'),
        text: this.getTransChoise('messages.delete_platform_text',{title:model.name}),
        type: "warning"
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch("Model/delete", model.id).then(() => {
            this.$toast.success(this.getTrans("messages.model") + ": " + this.getTrans("messages.delete_succeeded"));
          }).catch(()=>{
            this.$toast.success(this.getTrans("messages.model") + ": " + this.getTrans("messages.delete_failed"));
          });
        }
      });
    },
  }
}
</script>
