<template>
  <models-filter-list></models-filter-list>
</template>

<script>
import ModelsFilterList from './Components/ModelsFilterList.vue';
export default {
  name: 'studio-page',
  components: {
    ModelsFilterList
  },
}
</script>

<style>

</style>

