<template>
  <div class="card w-100">
    <div class="row p-1 px-2">
      <div class="col m-0 model-avatar">
        <ModelAvatar :model="model"></ModelAvatar>
      </div>
      <div class="col ml--2 my-auto model-title">
        <el-tooltip placement="top">
          <template #content >
            {{model.name}}
          </template>
          <template #default>
            <span class="row h3 mb--2 d-block">
              {{model.name}}
            </span>
          </template>
        </el-tooltip>
        <small class="row">{{model.currentBalance?model.currentBalance:'0'}} {{getTrans('messages.tokens')}}</small>
      </div>
      <div class="float-right model-menu">
        <model-menu :model="model" :size="'small'"><i class="fa fa-menu"></i></model-menu>
      </div>
    </div>
    <div class="row">
      <div class="col model-img mx-3 p-0" v-if="profiles.length" :style="{'background-image': 'url('+onlineImg+'), url(/img/diva_avatar.png)'}">
        <div class="model-options text-right p-2">
          <model-actions :model="model" :hideDropdown="true" :size="'sm'"></model-actions>
        </div>
      </div>
    </div>
    <div class="list-group list-group-flush model-profiles-list" v-if="profiles.length">
      <div class="list-group-item" v-for="profile in profiles" :key="profile.id">
        <model-profile-row :profile="profile" :showBoost="true"></model-profile-row>
      </div>
    </div>
    <div v-else class="model-no-profiles-container">
      <div class="row h-100">
        <div class="col-12 my-auto" align="center">
          <base-button type="primary" @click="addProfile">
            <i class="fas fa-plus"></i>
            {{getTrans('messages.add_profile')}}
          </base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ModelAvatar from './ModelAvatar';
import ModelMenu from './Components/ModelMenu.vue';
import ModelActions from './Components/ModelActions.vue';

import ModelProfileRow from '@/views/Studio/Components/ModelProfileRow.vue';
import {mapGetters} from 'vuex'
import StringFormatter from '../../mixins/StringFormatter.vue';
import { ElTooltip } from 'element-plus';
export default {
  name: 'ModelCard',
  components: {
    ModelAvatar,
    ModelProfileRow,
    ModelMenu,
    ModelActions,
    ElTooltip,
  },
  mixins: [
    StringFormatter,
  ],
  props: {
    model: {
      type: Object,
    }
  },
  data() {
    return {
      maxNameLength:10,
    }
  },
  computed: {
    ...mapGetters('Model', ['getCamsiteProfilesFromModel']),
    ...mapGetters('User', {getUser: 'getData', can: 'can'}),
    profiles() {
      return this.getCamsiteProfilesFromModel(this.model);
    },
    onlineImg() {
      let url = this.getCamsiteProfilesFromModel(this.model).filter(p=>p.performer?.data?.online)?.performer?.data?.screenshot_url;

      return url ? url : this.profiles.length ? this.profiles[0].performer.data.screenshot_url : ''
    }
  },
  methods: {
    addProfile() {
      this.$router.push({name:'model_profiles', params: {modelId: this.model.id} })
    }
  }
}
</script>