<template>
  <el-dropdown type="primary" trigger="click">
    <el-button :size="size">
      <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
    </el-button>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item v-for="option in dropdownOptionObjects" :key="option.value" @click="option.click">{{ option.title }}</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>

<script>
import ModelActionsMixin from "@/mixins/Model/ModelActionsMixin"
import { ElDropdown, ElDropdownItem, ElDropdownMenu, ElButton } from "element-plus"

export default {
  components: {
    ElDropdown, 
    ElDropdownItem, 
    ElDropdownMenu, 
    ElButton
  },
  mixins: [
    ModelActionsMixin,
  ],
  props: {
    size: {
      type: String,
      default: () => {
        return ''
      }
    }
  }
}
</script>

<style>

</style>