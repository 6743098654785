<template>
  <base-button @click="boost" :size="size" :type="type">
    <i class="fa fa-rocket" :class="{'pr-2':!only_icon}" ></i>
    <template v-if="!only_icon">{{getTrans('messages.boost')}}</template>
  </base-button>
</template>

<script>
export default {
  name: 'boost-button',  
  props: {
    size: {
      type: String,
      default: ()=>{
        return "";
      }
    },
    type: {
      type: String,
      default: ()=>{
        return "secondary";
      }
    },
    only_icon: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    profileIds: {
      type: Array,
      default: ()=>{
        return []
      },
    },
    packageId: {
      type: [String, Number],
      default: ()=>{
        return null;
      }
    },
    orderId: {
      type: String,
      default: ()=>{
        return null;
      }
    },
    link: {
      type: Boolean,
      default: ()=>{
        return false;
      }
    }
  },
  methods: {
    boost() {
      let bo = {}
      if (this.profileIds.length) {
        bo.profiles = JSON.stringify(this.profileIds.map(p=>{return {id:p}}));
      }
      if (this.orderId) {
        bo.orderId = this.orderId;
      }
      this.$router.push({name: 'boost', params: bo});
    }
  }
}
</script>