<template>
  <!-- eslint-->
  <diva-card>
    <div class="filters__container row mb-0 px-3">
      <div class="filters__item col-auto m-0 p-1">
        <el-select name="online_filter" v-model="filters.online_filter" class="filters__select" :label="getTrans('messages.online')">
          <el-option value="" :label="this.getTrans('messages.online_offline')"></el-option>
          <el-option value="true" :label="this.getTrans('messages.online_only')"></el-option>
          <el-option value="deactivated" :label="this.getTrans('messages.include_deactivated')"></el-option>
        </el-select>
      </div>
      <div class="filters__item col-auto m-0 p-1">
        <base-input :placeholder="getTrans('messages.search')" v-model="filters.search" type="text" :formClasses="'filters__input-wrapper'" :inputClasses="'filters__input'" @keyup="searchKeyPressed"></base-input>
      </div>
      <div class="filters__item col-auto m-0 p-1">
        <el-select name="platform_filter" v-model="filters.platform_filter" class="filters__select">
          <el-option v-for="p in platformOptions" :value="p.value" :key="p.value" :label="p.name"></el-option>
        </el-select>      
      </div>
      <div class="filters__item col-auto m-0 p-1">
        <el-select name="sorting" v-model="filters.sorting" class="filters__select" :placeholder="getTrans('messages.sorting')">
          <el-option v-for="option in sortingOptions" :value="option.value" :key="option.value" :label="option.name"></el-option>
        </el-select>      
      </div>
      <div class="filters__item col-auto m-0 p-1">
        <base-button size="sm" type="success" @click="searchClick">{{getTrans('messages.search')}}</base-button>
        <base-button size="sm" type="warning" @click="resetFilters">{{getTrans('messages.reset')}}</base-button>
      </div>
    </div>
  </diva-card>
  <diva-card v-if="loading">
    <content-loader></content-loader>
  </diva-card>
  <div class="models-list-container w-100" v-else>
    <div class="w-100">
        <div v-if="pagination.total_pages>1">
          <store-pagination :store="'Model'" @update:modelValue="selectPage"></store-pagination>
        </div>
    </div>
    <div class="models-list">
      <div class="models-list__card" v-for="model in getModels" :key="model.id">
        <model-card :model="model"></model-card>
      </div>
      <div class="models-list__card">
        <div class="card w-100">
          <div class="card-body">
            <div class="row h-100">
              <div class="col-12 my-auto" align="center">
                <base-button :type="'primary'" @click="gotoCreateModel">
                  <i class="fas fa-plus"></i>
                  {{getTrans('messages.add_model')}}
                </base-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { mapGetters } from 'vuex';
import ModelCard from '../ModelCard.vue';
import { ElSelect, ElOption } from 'element-plus';
import StorePagination from "@/components/StorePagination";

export default {
  name: "models-filter-list",
  components: {
    ElSelect, 
    ElOption,
    ModelCard,
    StorePagination,
  },
  data() {
    return {
      searchForMore: false,
      canLoadMore: true,
      filters: {
      //defaults in resetFilter()
      },
      sortingOptions: [
        {
          name: this.getTrans("messages.newest_first"),
          value: "sorting[id]=desc",
        },
        {
          name: this.getTrans("messages.oldest_first"),
          value: "sorting[id]=asc",
        },
        {
          name: this.getTrans("messages.name_az"),
          value: "sorting[name]=asc",
        },
        {
          name: this.getTrans("messages.name_za"),
          value: "sorting[name]=desc",
        }
      ],
    };
  },
  computed: {
    ...mapGetters("Model", {
      getModels: "getList",
      loading: "listLoading",
      modelPagination: "pagination",
    }),
    ...mapGetters("Platform", {
      getPlatforms: "getList"
    }),
    platformOptions() {
      var options = !this.getPlatforms ? [] : this.getPlatforms.map(p => {
        return { value: p.id, name: p.title };
      });
      options.unshift({ value: "", name: this.getTrans("messages.all_platforms") });
      return options;
    },
    pagination() {
      if (this.modelPagination) {
        return this.modelPagination;
      }
      return {};
    }
  },
  methods: {
    resetFilters() {
      this.filters = {
        online_filter: "",
        search: "",
        platform_filter: "",
        sorting: "",
        page: 1,
      };
    },
    searchClick() {
      this.filters.page = 1;
      this.filtersChanged();
    },
    gotoCreateModel() {
      this.$router.push({name:'add_model'})
    },
    getFilterObject(f) {
      let filter = { sorting: this.sorting };
      if (f.search.length >= this.MIN_SEARCH_LENGTH_COMP) {
        filter.name_search = f.search;
      }
      filter.with_trashed = f.online_filter === "deactivated" ? 1 : 0;
      if (typeof online_filter === typeof true) {
        filter.status = f.online_filter;
      }
      if (f.platform_filter) {
        filter.platforms = f.platform_filter;
      }
      filter.page = f.page;
      return filter;
    },
    filtersUpdated(newFilter) {
      Object.keys(newFilter).forEach(key=>{
        this.filters[key] = newFilter[key];
      });
    },
    filtersChanged() {
      this.load(this.filters);
    },
    load(filters) {
      if (!this.canLoadMore){
          return;
      }
      let options = this.getFilterObject(filters);
      if(options.name_search) {
          this.lastHadSearch = true;
      } else {
          this.lastHadSearch = false;
      }
      return this.$store.dispatch('Model/getList', options).then(()=>{
          if (this.searchForMore) {
              this.filtersChanged();
              this.searchForMore = false;
          }
      })
    },
    selectPage(pageNum) {
      this.filters.page = pageNum;
      this.filtersChanged();
    },
    searchKeyPressed(key) {
      if (key==='Enter') {
        this.selectPage(1);
      }
    }
  },
  mounted() {
    this.$store.dispatch('Platform/get')
    this.resetFilters();
    this.filtersChanged();
  }
}
</script>