<template>
<div>
  <CircleImage v-if="profile"
    :alt=" profile.performer.data.name + ' image'"
    :url=" userAvatarUrlByid(model.id)"
  ></CircleImage>
</div>
</template>

<script>
import CircleImage from '@/components/Images/CircleImage'
import {mapGetters} from 'vuex';
export default {
  name: 'ModelAvatar',
  components: {
    CircleImage,
  },
  props: {
    model: {
      type: Object,
    },
  },
  computed: {
    ...mapGetters('User', ['userAvatarUrlByid']),
    profile() {
      if(this.model && this.model.profiles && this.model.profiles.data.length>0) {
        return this.model.profiles.data[0];
      } else if(this.model && this.model.profiles && this.model.profiles.length>0) {
        return this.model.profiles[0];
      }
      return null;
    }
  }
}
</script>

<style>

</style>
