<template>
  <base-button v-if="hideDropdown" type="primary" :size="size" @click="buttonOptionObject.click"><i class="mr-1" :class="buttonOptionObject.icon" v-if="buttonOptionObject.icon"></i>{{buttonOptionObject.title}}</base-button>
  <el-dropdown split-button type="primary" @click="buttonOptionObject.click" v-else>
    {{ buttonOptionObject.title }}
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item v-for="option in dropdownOptionObjects" :key="option.value" @click="option.click">{{ option.title }}</el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>
<script>
import ModelActionsMixin from "@/mixins/Model/ModelActionsMixin"
import { ElDropdown, ElDropdownMenu, ElDropdownItem } from "element-plus";
import BaseButton from "../../../components/BaseButton.vue";
export default {
  name: 'model-actions',
  mixins: [ModelActionsMixin],
  components: {
    ElDropdown,
    ElDropdownMenu,
    ElDropdownItem,
    BaseButton
  },
  props: {
    hideDropdown: {
      type: Boolean,
      default: ()=>{
        return false;
      }
    },
    size: {
      type: String,
      default: ()=> {
        return ''
      }
    }
  }
}
</script>