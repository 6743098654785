<template>
<div class="row w-100">
  <div class="model-profile-avatar-img ml-2 pl-2 my-auto">
    <ProfileAvatar :profile="profile" :showTooltip="false"></ProfileAvatar>
  </div>
  <div class="col my-auto model-profile-name">
      <el-tooltip placement="bottom" transition="">
        <template #content> 
          {{profileName(profile)}}
        </template>          
        <span class=" w-100">{{profileName(profile)}}</span>
      </el-tooltip>
    <div class="profile-card__platform-logo-container">
      <img class="profile-card__platform-logo" :src="platformLogo(profilePlatformId(profile))"/>
    </div>
  </div>
  <div class="model-profile-boost">
    <diva-boost-button :profile="profile" :only_icon="true" size="sm" type="primary"></diva-boost-button>
  </div>
</div>
</template>

<script>

import { mapGetters } from 'vuex';
import ProfileAvatar from "@/views/Profiles/Components/ProfileAvatar";
import ProfileMixin from "@/mixins/Profile/ProfileMixin";
import PlatformMixin from "@/mixins/PlatformMixin";
import StringFormatter from '@/mixins/StringFormatter.vue';
import DivaBoostButton from '@/components/Diva/DivaBoostButton.vue';
import { ElTooltip } from 'element-plus';
export default {
  name: 'model-profile-row',
  components:{
    ProfileAvatar,
    DivaBoostButton,
    ElTooltip,
  },
  emits: ['delete'],
  mixins: [PlatformMixin, ProfileMixin, StringFormatter],
  props: {
    profile: {
      type: Object,
      required: true,
    },
    maxProfiles: {
      type: Number,
      default: 10
    },
    showTooltip: {
      type: Boolean,
      default: true
    },
    showDelete: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters('Profile', ['getType', 'getProfileLandingUrl']),
    getStyle() {
      return {width:this.size+'px', height:this.size+'px'}
    },
  },
  methods: {
    removeProfile(profile) {
      this.$emit('delete', profile);
    },
  }
};
</script>